<template>
  <div class="refund-main ph-2x">
    <!-- 导航栏 -->
    <van-nav-bar
      fixed
      placeholder
      title="退款"
      left-arrow
      @click-left="$router.back()"
    />
    <div
      class="refund-item mt-2x font-s pv-2x ph-2x"
      v-for="item in list"
      :key="item.id"
    >
      <div class="flex-between">
        <div class="flex-between-item">充值单号：{{ item.totalOrderId }}</div>
        <div class="flex-between-item flex-end">
          <button
            class="refund-btn font-s"
            hover-class="click"
            :style="{ color: color, 'border-color': color }"
            :disabled="item.payStatus !== 2 || item.remainingSum <= 0"
            @click="refund(item)"
          >
            退款
          </button>
        </div>
      </div>
      <div>充单时间：{{ item.tradingHour | date("yyyy-mm-dd hh:MM") }}</div>
      <div>资产名称：{{ item.productName }}</div>
      <div class="flex-between">
        <div class="flex-between-item">
          充单状态：{{ item.payStatus === 1 ? "已退款" : "充值成功 " }}
        </div>
        <div class="flex-between-item">支付方式：{{ item.payWayName }}</div>
      </div>
      <div class="flex-between">
        <div class="flex-between-item">
          现金价值：{{ item.paymentAmount | money() }}
        </div>
        <div class="flex-between-item">
          充值数额：{{ item.totalAmount | money() }}
        </div>
      </div>
    </div>
    <!-- 暂无数据 -->
    <van-empty
      v-if="list.length === 0"
      image="error"
      description="暂无数据"
    ></van-empty>
    <!-- 退款弹窗 -->
    <van-dialog
      v-model="isShowReturnInfo"
      title="退款"
      show-cancel-button
      theme="round-button"
      :confirm-button-color="$store.getters['common/color'].primary"
      cancel-button-color="#f1f1f1"
      close-on-click-overlay
      @cancel="changeRefundInfo"
      @confirm="refundAssets"
    >
      <div class="">
        <div class="pop-centent-center">
          <div class="refund-info">
            <div>订单号：{{ refundInfo.totalOrderId }}</div>
            <div>资产名称：{{ refundInfo.productName }}</div>
            <div>会员余额：{{ refundInfo.remainingSum | money() }}</div>
            <div>退款数额：{{ refundInfo.changeNum | money() }}</div>
            <div>现金价值：{{ refundInfo.finallyNum | money() }}</div>
            <div>折算后应退金额：{{ refundInfo.paymentAmount | money() }}</div>
          </div>
          <div class="refund-remake">
            <div class="">退款说明</div>
            <van-field
              class="refund-textarea"
              v-model="remark"
              rows="2"
              autosize
              type="textarea"
              placeholder="请输入..."
            />
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import {
  findMemberAssetsInfo,
  memberRefundBalance,
  memberAssetsInfoMini,
} from "../../api/member.js"; // MS-会员接口
export default {
  name: "MemberRefund",
  data() {
    return {
      list: [], //资产列表
      isShowReturnInfo: false, // 显示退款弹窗
      refundInfo: [], // 退款信息
      remark: "", // 退款备注
      detailItem: [], //退款数据
      memberId: "", // 会员id
    };
  },
  created() {
    const { memberId } = this.$route.query;
    this.memberId = memberId;
    this.loadData();
  },
  computed: {
    // 主题配色变量
    color() {
      return this.$store.getters["common/color"].primary;
    },
  },
  methods: {
    // 显示隐藏退款弹窗
    changeRefundInfo() {
      this.isShowReturnInfo = !this.isShowReturnInfo;
    },
    // 退款确认
    refundAssets() {
      let row = this.detailItem;
      memberAssetsInfoMini({
        memberBusinessType: 3, // 会员退余额类型 3
        buyChannels: 6, // 会员退余额类型 6
        tradingType: 2, // 退余额/减少 2
        memberAssetsInfoId: row.id, // 流水表id
        originOrderId: row.totalOrderId, // 原订单号id
        merchantId: row.merchantId, // 景区id
        memberId: row.memberId, // 会员id
        remark: this.remark,
      })
        .then(() => {
          this.$toast("退款成功");
          this.isShowReturnInfo = false;
          setTimeout(() => {
            this.loadData();
          }, 900);
        })
        .catch(() => {});
    },
    // 加载数据
    loadData() {
      findMemberAssetsInfo({
        memberId: this.memberId,
      })
        .then((res) => {
          this.list = res;
        })
        .catch(() => {});
    },
    // 显示退款弹窗数据
    refund(item) {
      memberRefundBalance({
        merchantId: item.merchantId,
        memberId: this.memberId,
        memberAssetsInfoId: item.id,
      })
        .then((res) => {
          this.detailItem = item;
          this.refundInfo = res;
          this.isShowReturnInfo = true;
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
@import "./detail";
.refund-main {
  .refund-item {
    background: #ffffff;
    border-radius: 8px;
    line-height: 25px;
    color: #272727;
    .flex-between-item {
      width: 50%;
      .refund-btn {
        width: 60px;
        height: 26px;
        line-height: 26px;
        border-radius: 14px;
        border: 1px solid #2c9e6c;
        background: #ffffff;
      }
      .refund-btn::after {
        border: none;
      }
    }
  }
}
</style>
