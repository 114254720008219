<template>
  <div>
    <!-- 导航栏 -->
    <van-nav-bar fixed placeholder :title="title" left-arrow />
    <!-- 产品详情 且只有一个票号时显示 -->
    <!-- 订单详情 -->
    <order-detail
      :detail-data="detailData"
      @search-detail="searchDetail"
      v-if="!showTicket"
    ></order-detail>
    <ticket-detail
      :result-detail="resultDetail"
      @search-detail="searchDetail"
      v-else
    ></ticket-detail>
  </div>
</template>

<script>
import { searchReportOrderDetails } from "../../api/order";
import { orderMobilemanagerProductdetails } from "../../api/search";
import OrderDetail from "./OrderDetail.vue";
import TicketDetail from "./TicketDetail.vue";
export default {
  name: "OrderDetails",
  components: {
    OrderDetail,
    TicketDetail,
  },
  created() {
    this.id = this.$route.query.id;
    this.loadData();
  },
  data() {
    return {
      id: "",
      showDetail: false,
      detailData: {},
      showTicket: false,
      resultDetail: {},
      title: "订单详情",
    };
  },
  methods: {
    loadData() {
      searchReportOrderDetails({
        id: this.id,
      })
        .then((res) => {
          this.detailData = res;
          this.title = "订单详情";
          this.showTicket = false;
        })
        .catch(() => {});
    },
    searchDetail(item) {
      orderMobilemanagerProductdetails({
        keyword: item,
      })
        .then((res) => {
          this.resultDetail = res[0];
          this.showTicket = true;
          this.title = "产品详情";
        })
        .catch(() => {});
    },
  },
};
</script>

<style></style>
