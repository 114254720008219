<template>
  <div class="page">
    <!-- 导航栏 -->
    <van-nav-bar
      placeholder
      title="套餐详情"
      left-arrow
      @click-left="$router.back()"
      bg-color="#43435c"
    />
    <!-- 套餐信息 -->
    <div class="pt-3x ph-2x top">
      <div class="combo-info ph-2x pt-1x">
        <div class="title">{{ comboItem.name }}</div>
        <div class="flex-between font-s">
          <div class="flex-between-item">
            会员充值余额：{{ comboItem.sellingPrice | money() }}
          </div>
          <div class="flex-between-item">
            赠送积分：{{ comboItem.spuSpecInfo.giftPoints }}
          </div>
        </div>
        <div class="flex-between font-s">
          <div class="flex-between-item">
            赠送成长值：{{ comboItem.spuSpecInfo.giftGrowth }}
          </div>
          <div
            class="flex-between-item text-1"
            @click="clickProduct(comboItem, 0)"
          >
            赠送优惠券：
            <template v-if="comboItem.giftCoupons">{{
              comboItem.giftCoupons.join(";")
            }}</template>
          </div>
        </div>
      </div>
    </div>
    <div class="combo-title font-xl">资产明细</div>
    <div
      class="item flex-between pv-2x ph-2x"
      v-for="item in assetsList"
      :key="item.id"
      @click="clickProduct(item, 1)"
    >
      <div class="flex-between-l">
        <div class="font-xl mb-1x">{{ item.name }}</div>
        <div class="font-s text-3">
          可用产品：
          <span class="product">{{
            item.applicationNameArr || "全部产品"
          }}</span>
        </div>
      </div>
      <div class="flex-between-r font-xl text-r">
        {{ item.spuSpecInfo.cashValue | money() }}
      </div>
    </div>
    <!-- 可用产品详情 -->
    <van-dialog v-model="showModal" title="可用产品">
      <div class="slot-content" @click="confirm">
        {{ content }}
      </div>
    </van-dialog>
    <div class="footer">
      <div class="flex-center">
        <button
          class="buy-btn text-c white"
          :disabled="disabled"
          @click="buyCombo"
        >
          购买
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  productFrontPropertyPackage,
  productFrontMemberProperty,
} from "../../api/search";
import { evidenceOneMemberInfo } from "../../api/member.js"; // MS-会员接口
import { memberRecharge } from "../../api/cart";
export default {
  name: "combo-detail",
  data() {
    return {
      id: "", // 套餐详情id
      comboItem: {
        spuSpecInfo: [],
      }, // 套餐信息
      assetsList: [], //资产明细
      showModal: false, // 显示弹窗
      content: "", // 弹窗内容
      title: "可用产品", // 弹窗标题
      disabled: false, // 是否可点击购买按钮
      memberId: "", // 会员id
      evidence: "", // 会员凭证
      memberDetail: {}, //会员信息
    };
  },
  created() {
    const { id, memberId, evidence } = this.$route.query;
    this.memberId = memberId;
    this.evidence = evidence;
    this.id = id;
    this.loadData();
    this.getUserInfo();
  },
  computed: {
    // 主题配色变量
    color() {
      return this.$store.getters["common/color"].primary;
    },
  },
  methods: {
    // 获取会员信息
    getUserInfo() {
      evidenceOneMemberInfo({
        evidence: this.evidence,
      })
        .then((res) => {
          this.memberDetail = res;
        })
        .catch(() => {});
    },
    // 购买
    buyCombo() {
      this.disabled = true;
      memberRecharge({
        giftMemberPhones: [{ productId: this.id }],
        purchaserMemberId: this.memberId, // 会员id,
        rechargeTo: this.evidence,
        whetherTemporaryMember: this.memberDetail.whetherTemporaryMember,
      })
        .then((res) => {
          this.disabled = false;
          this.$router.push({
            path: "/record/ConfirmOrder",
            query: {
              type: "recharge",
              id: res.id,
              totalPrice: res.totalPrice,
              settlementPrice: res.settlementPrice,
            },
          });
        })
        .catch(() => {
          this.disabled = false;
        });
    },
    // 显示弹窗
    clickProduct(item, type) {
      if (type === 0) {
        this.content = item.giftCoupons.join(";");
        this.title = "赠送优惠券";
      } else {
        this.title = "可用产品";
        this.content = item.applicationNameArr || "全部产品";
      }
      this.showModal = true;
    },
    // 关闭弹窗
    confirm() {
      this.showModal = false;
    },
    // 加载套餐
    loadData() {
      productFrontPropertyPackage({})
        .then((res) => {
          const obj = res.find((item) => {
            if (item.id === this.id) {
              return item;
            }
          });
          this.comboItem = obj;
          this.loadDetail();
        })
        .catch(() => {});
    },
    // 加载资产明细
    loadDetail() {
      productFrontMemberProperty({
        id: this.id,
      })
        .then((res) => {
          res.forEach((item) => {
            let applicationNameArr = [];
            item.applicableProducts = JSON.parse(item.applicableProducts) || [];
            item.applicableProducts.forEach((temp) => {
              applicationNameArr.push(temp.productName);
            });
            item.applicationNameArr = applicationNameArr.join(";");
          });
          this.assetsList = res;
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="less" scoped>
.page {
  min-height: 100vh;
  background: #fff;
}
.top {
  background: #43435c;
  .combo-info {
    width: 343px;
    height: 108px;
    border-radius: 8px 8px 0px 0px;
    line-height: 30px;
    background: url("https://local-qmp.oss-cn-shenzhen.aliyuncs.com/AppDatas/16492/1649295509968.png")
      no-repeat;
    background-size: 100% 100%;
    color: #272727;
    .title {
      font-size: 44rpx;
      color: #7a4918;
    }
    .flex-between-item {
      width: 50%;
    }
  }
}
.combo-title {
  text-align: center;
  border-bottom: 1px solid #f1f1f1;
  height: 53px;
  line-height: 53px;
  font-weight: bold;
}
.item {
  border-bottom: 1px solid #f1f1f1;
  .flex-between-l {
    width: 80%;
    .product {
      color: #8a8a8a;
    }
  }
  .flex-between-r {
    width: 20%;
  }
}
.slot-content {
  width: 320px;
  padding: 15px;
  max-height: 70vh;
}
.footer {
  position: fixed;
  bottom: 16px;
  left: 0px;
  right: 0px;
  .buy-btn {
    width: 240px;
    height: 45px;
    background: #deaf70;
    border-radius: 23px;
    line-height: 45px;
    border: none;
  }
}
</style>
