<template>
  <div>
    <!-- 导航栏 -->
    <van-nav-bar
      placeholder
      title="确认订单"
      left-arrow
      @click-left="$router.back()"
    />
    <div class="order-price-info flex-between font-xl ph-2x pt-2x">
      <span class="">订单金额</span>
      <span class="red bold">{{ totalPrice | money() }}</span>
    </div>
    <div
      class="order-price-info flex-between font-xl ph-2x pt-2x"
      v-if="refundPrice > 0"
    >
      <span class="">退款金额</span>
      <span class="red bold">{{ refundPrice | money() }}</span>
    </div>
    <div class="order-price-info flex-between font-xl ph-2x pv-2x bb">
      <span class="">实付金额</span>
      <span class="red bold">{{
        (settlementPrice - refundPrice) | money()
      }}</span>
    </div>
    <!-- 选择支付方式组件 -->
    <pay-way
      ref="PayWay"
      :show-pay-way="true"
      :cart-id="cartId"
      :page-type="type"
      :show-pay-after="showPayAfter"
      :is-order="isOrder"
      @success="success"
    />
  </div>
</template>

<script>
import PayWay from "../../components/PayWay.vue";
export default {
  name: "confirm-order",
  components: {
    PayWay,
  },
  data() {
    return {
      cartId: 0, //购物车id
      orderInfo: [], // 订单信息
      type: "", // 类型
      totalPrice: 0, // 订单金额
      settlementPrice: 0, // 实付金额
      orderId: 0,
      refundPrice: 0, //退款金额
      showPayAfter: true,
      isOrder: false, // 是否订单
      showSearch: true,
    };
  },
  created() {
    const {
      id,
      type,
      totalPrice,
      settlementPrice,
      orderId,
      refundPrice,
      showSearch,
    } = this.$route.query;
    this.cartId = Number(id) || 0;
    this.orderId = orderId || 0;
    this.refundPrice = refundPrice || 0;
    this.settlementPrice = settlementPrice || 0;
    this.totalPrice = totalPrice || 0;
    this.type = type;
    this.showSearch = showSearch || true;
    // 有传订单号就将订单号赋给支付组件
    if (orderId) {
      this.$nextTick(() => {
        this.$refs.PayWay.orderId = orderId;
        this.showPayAfter = false;
        this.isOrder = true;
        this.$refs.PayWay.refundPrice = refundPrice;
        this.$refs.PayWay.settlementPrice = settlementPrice;
      });
    }
  },
  computed: {
    // 主题配色变量
    color() {
      return this.$store.getters["common/color"].primary;
    },
  },
  methods: {
    // 成功返回
    success(item) {
      if (item) {
        if (this.type === "recharge") {
          this.$router.go(-2);
        } else if (this.type === "closeoutaccount") {
          this.$router.replace({
            path:
              "/record/CloseoutAccount?orderId=" +
              this.orderId +
              "&showSearch=" +
              this.showSearch,
          });
        } else {
          this.$router.back();
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.order-price-info {
  background: #ffffff;
}
.bb {
  border-bottom: 1px solid #f1f1f1;
}
</style>
