<template>
  <div class="">
    <!-- 导航栏 -->
    <van-nav-bar
      fixed
      placeholder
      title="优惠券"
      left-arrow
      @click-left="$router.back()"
    />
    <!-- 头部筛选栏 -->
    <div class="flex-around coupon-type">
      <div
        class="coupon-type-item"
        :class="{ selected: typeId === 1 }"
        :style="{
          color: typeId === 1 ? color : '',
          'border-color': typeId === 1 ? color : '',
        }"
        @click="changeTypeId(1)"
      >
        可用（{{ couponsAvailableCount }}）
      </div>
      <div
        class="coupon-type-item"
        :class="{ selected: typeId === 2 }"
        :style="{
          color: typeId === 2 ? color : '',
          'border-color': typeId === 2 ? color : '',
        }"
        @click="changeTypeId(2)"
      >
        过期（{{ unavailableCount }}）
      </div>
      <div
        class="coupon-type-item"
        :class="{ selected: typeId === 3 }"
        :style="{
          color: typeId === 3 ? color : '',
          'border-color': typeId === 3 ? color : '',
        }"
        @click="changeTypeId(3)"
      >
        已使用（{{ expiredCount }}）
      </div>
    </div>
    <!-- 优惠券列表 -->
    <div class="p-2x">
      <div class="item" v-for="(item, index) in couponList" :key="index">
        <div class="coupon-info flex-between">
          <div class="coupon-bg">
            <!-- <span class="symbol">￥</span> -->
            {{ item.discountedPriceText }}
          </div>
          <div class="coupon-info-r">
            <div class="">{{ item.useConditionsText }}</div>
            <div class="validity mt-1x">
              有效期：
              {{ item.activityStartDate | date("yyyy-mm-dd hh:MM:ss") }} ~
              {{ item.activityEndDate | date("yyyy-mm-dd hh:MM:ss") }}
            </div>
          </div>
        </div>
        <div class="coupon-details">
          适用产品：<span class="validity">
            {{ item.productText }}
          </span>
        </div>
      </div>
      <van-empty
        v-if="couponList.length === 0"
        image="error"
        description="暂无优惠券"
      ></van-empty>
    </div>
  </div>
</template>
<script>
import {
  marketingCouponList,
  findMiniMemberCouponsCount,
} from "../../api/marketing";
export default {
  name: "MemberCoupon",
  data() {
    return {
      typeId: 1, // 类型下标
      memberId: 0, // 会员id
      couponList: [], // 优惠券列表
      couponsAvailableCount: 0, //可用优惠券(未过期与未使用的)
      expiredCount: 0, // 已过期
      unavailableCount: 0, // 已使用
    };
  },
  computed: {
    // 主题配色变量
    color() {
      return this.$store.getters["common/color"].primary;
    },
  },
  // 进入页面触发
  created() {
    const { memberId } = this.$route.query;
    if (memberId) {
      this.memberId = memberId;
      this.getMarketingCouponList();
      findMiniMemberCouponsCount({
        memberId: this.memberId,
      })
        .then((res) => {
          this.couponsAvailableCount = res.couponsAvailableCount;
          this.expiredCount = res.expiredCount;
          this.unavailableCount = res.unavailableCount;
        })
        .catch(() => {});
    }
  },
  methods: {
    // 切换优惠券类型
    changeTypeId(typeId) {
      this.typeId = typeId;
      this.getMarketingCouponList();
    },
    // 获取优惠券
    getMarketingCouponList() {
      marketingCouponList({
        memberId: this.memberId,
        status: this.typeId,
      })
        .then((res) => {
          this.couponList = res;
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.coupon-type {
  background: #fff;
  height: 49px;
  line-height: 49px;
  .coupon-type-item {
    width: 33%;
    text-align: center;
  }
  .selected {
    color: @primary;
    border-bottom: 2px solid @primary;
  }
}
.item {
  background: #fff;
  border-radius: 4px;
  margin-bottom: 10px;
  .coupon-info {
    padding: 11px 15px 13px;
    border-bottom: 1px solid #f1f1f1;
    .coupon-bg {
      background: url("../../assets/images/record/youhuiq@2x.png") no-repeat;
      background-size: 100% 100%;
      width: 86px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      color: #fff;
      font-size: 20px;
      font-weight: bold;
      .symbol {
        font-size: 12px;
        font-weight: initial;
      }
    }
    .coupon-info-r {
      width: calc(100% - 86px - 11px);
    }
  }
  .validity {
    font-size: 12px;
    color: #8a8a8a;
  }
  .coupon-details {
    padding: 11px 15px 13px;
    font-size: 12px;
  }
}
</style>
