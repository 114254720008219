<template>
  <div class="">
    <!-- 自定义导航栏 -->
    <!-- 导航栏 -->
    <van-nav-bar
      title="充值"
      left-arrow
      @click-left="$router.back()"
      bg-color="bgColor"
      :placeholder="false"
    />
    <img
      src="https://local-qmp.oss-cn-shenzhen.aliyuncs.com/AppDatas/16492/1649295535341.png"
      class="recharge-img"
    />
    <!-- 分割线 -->
    <div class="flex-center">
      <div class="divider text-c">
        <van-divider
          :style="{
            color: '#8a8a8a',
            borderColor: '#D8DBDD',
            'font-size': '18px',
          }"
          >充值限时优惠</van-divider
        >
      </div>
    </div>
    <!-- 充值套餐 -->
    <div class="flex-between flex-wrap ph-2x">
      <div
        class="item"
        v-for="item in dataList"
        :key="item.id"
        @click="detail(item)"
      >
        <div>
          <span class="symbol">￥</span
          ><span class="price">{{ item.sellingPrice }}</span>
        </div>
        <div class="text-1">{{ item.name }}</div>
        <div class="flex-center font-s detail-color">
          <span class="mr-1x">详情</span>
          <span class="icon icon-Return-"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { productFrontPropertyPackage } from "../../api/search";
export default {
  name: "RechargeCombo",
  data() {
    return {
      bgColor: "rgba(255,255,255,0)", // 导航栏背景色
      leftIconColor: "#ffffff", // 导航栏返回图标颜色
      dataList: [], // 套餐列表
      memberId: "", // 会员id
      evidence: "", //会员凭证
    };
  },
  created() {
    const { memberId, evidence } = this.$route.query;
    this.memberId = memberId;
    this.evidence = evidence;
    this.loadData();
  },
  methods: {
    // 加载套餐
    loadData() {
      productFrontPropertyPackage({})
        .then((res) => {
          res.forEach((item) => {
            item.sellingPrice = item.sellingPrice / 100;
          });
          this.dataList = res;
        })
        .catch(() => {});
    },
    detail(item) {
      this.$router.push({
        path: "/record/ComboDetail",
        query: {
          id: item.id,
          memberId: this.memberId,
          evidence: this.evidence,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.nav-bar {
  font-size: 18px;
  text-align: center;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  color: #fff;
}
.recharge-img {
  width: 100%;
  height: 242px;
  display: block;
}
.divider {
  width: 225px;
}
.item {
  width: 48%;
  background: #fff;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 11px;
  padding: 8px;
  .symbol {
    font-size: 16px;
    font-weight: bold;
    color: #ef9c30;
  }
  .price {
    font-size: 32px;
    font-weight: bold;
    color: #ef9c30;
  }
  .detail-color {
    color: #aaaaaa;
  }
}
</style>
