<template>
  <div>
    <!-- 导航栏 -->
    <van-nav-bar
      fixed
      placeholder
      title="清账"
      left-arrow
      @click-left="$router.back()"
    />
    <!-- 搜索框 -->
    <div
      :style="{ backgroundColor: color }"
      class="p-2x"
      v-if="showSearch == true"
    >
      <div class="search-box flex-between">
        <div>
          <van-icon name="search" size="17" color="#B8B8B8" class="ph-1x" />
          <input
            type="text"
            class="search-inp"
            v-model="searchKey"
            placeholder="请输入会员手机号或挂账单号"
          />
        </div>
        <button
          class="search-btn"
          :style="{ backgroundColor: color }"
          @click="onSearch"
        >
          搜索
        </button>
      </div>
    </div>
    <!-- 订单列表 -->
    <div class="item" v-for="(item, index) in orderList" :key="index">
      <p class="mb-1x">总单号：{{ item.mainOrderId }}</p>
      <p class="mb-1x">总单页态集：{{ item.ownerFormatTypeInfo }}</p>
      <div class="flex-between">
        <p>下单时间：{{ item.createTime | date("YYYY-MM-DD hh:mm") }}</p>
        <button
          class="closeout-btn"
          :style="{ borderColor: color, color }"
          @click="goCloseout(item.mainOrderId)"
        >
          清账
        </button>
      </div>
    </div>
    <van-empty
      image="search"
      v-if="orderList.length === 0"
      description="描述文字"
    />
  </div>
</template>

<script>
import { findOncreditOrderList } from "../../api/order";
export default {
  name: "CloseoutAccountList",
  computed: {
    // 主题配色变量
    color() {
      return this.$store.getters["common/color"].primary;
    },
  },
  data() {
    return {
      searchKey: "", //搜索值
      showSearch: true,
      orderList: [],
    };
  },
  created() {
    this.searchKey = this.$route.query.search;
    if (this.searchKey) {
      this.onSearch();
    }
  },
  methods: {
    onSearch() {
      console.log("2121");
      findOncreditOrderList({
        code: this.searchKey,
      })
        .then((res) => {
          this.orderList = res;
        })
        .catch(() => {});
    },
    goCloseout(orderId) {
      this.$router.push({
        path:
          "/record/CloseoutAccount?orderId=" +
          orderId +
          "&page=list&search=" +
          this.searchKey,
      });
    },
  },
};
</script>

<style lang="less" scoped>
// 搜索框
.search-box {
  background: #fff;
  border-radius: 4px;
  height: 34px;
  .search-inp {
    border: none;
    height: 34px;
    width: 55vw;
  }
  .search-btn {
    color: #fff;
    border: none;
    width: 60px;
    height: 30px;
    border-radius: 6px;
    margin-right: 2px;
  }
}
.item {
  background: #fff;
  border-bottom: 1px solid #f1f1f1;
  color: #666;
  padding: 16px;
}
.closeout-btn {
  width: 80px;
  height: 34px;
  border-radius: 17px;
  border: 1px solid;
  background: #fff;
  &:active {
    opacity: 0.5;
  }
}
</style>
