<template>
  <div>
    <!-- 导航栏 -->
    <van-nav-bar
      fixed
      placeholder
      title="清账"
      left-arrow
      left-text="返回"
      @left-click="onClickLeft"
    />
    <!-- 搜索框 -->
    <div
      :style="{ backgroundColor: color }"
      class="p-2x"
      v-if="showSearch == true"
    >
      <div class="search-box flex-between">
        <div>
          <van-icon name="search" size="17" color="#B8B8B8" class="ph-1x" />
          <input
            type="text"
            class="search-inp"
            v-model="searchKey"
            placeholder="请输入单号"
          />
        </div>
        <button
          class="search-btn"
          :style="{ backgroundColor: color }"
          @click="onSearch"
        >
          搜索
        </button>
      </div>
    </div>
    <div v-else class="p-2x bg-white bd-b">总单号:{{ searchKey }}</div>
    <!-- 产品 -->
    <div class="list">
      <div class="item p-2x" v-for="item in productInfo" :key="item.id">
        <p>{{ item.productName }}</p>
        <div class="flex-between">
          <div>数量：{{ item.buyNum }}</div>
          <div class="num-box flex-start p-1x">
            <span class="font-s">确认清账：</span>
            <input
              @blur="blurNum(item)"
              type="number"
              class="num-inp"
              v-model="item.num"
              placeholder="请输入"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 底部按钮 -->
    <div class="foot pv-1x ph-2x">
      <button
        :style="{ backgroundColor: color }"
        class="foot-btn p-1x"
        @click="ok"
      >
        确认
      </button>
    </div>
  </div>
</template>

<script>
import { findOncreditOrderInfo, settleOncreditOrder } from "../../api/order";
export default {
  name: "CloseoutAccount",
  computed: {
    // 主题配色变量
    color() {
      return this.$store.getters["common/color"].primary;
    },
  },
  data() {
    return {
      searchKey: "", //搜索值
      productInfo: [],
      showSearch: true,
      page: "", // 页面路径
      search: "", //上个页面搜索参数
    };
  },
  created() {
    this.page = this.$route.query.page;
    this.search = this.$route.query.search;
    this.searchKey = this.$route.query.orderId;
    this.showSearch = this.$route.query.showSearch;
    if (this.searchKey) {
      this.onSearch();
    }
  },
  methods: {
    onClickLeft() {
      if (this.page === "list") {
        this.$router.replace({
          path: "/record/CloseoutAccountList",
          query: {
            type: "closeoutaccount",
            search: this.search,
          },
        });
      } else {
        this.$router.back();
      }
    },
    // 搜索
    onSearch() {
      findOncreditOrderInfo({ orderId: this.searchKey })
        .then((res) => {
          if (res) {
            res.forEach((val) => {
              val.num = val.buyNum;
            });
            this.productInfo = res;
          } else {
            this.productInfo = [];
          }
        })
        .catch(() => {});
    },
    // 输入数量
    blurNum(item) {
      if (Number(item.num) > Number(item.buyNum)) {
        this.$toast("不能大于产品数量");
        item.num = item.buyNum;
        return;
      }
      if (!item.num) {
        item.num = 0;
      }
    },
    // 确认
    ok() {
      let total = 0;
      let changeProducts = [];
      this.productInfo.forEach((item) => {
        total += Number(item.num);
        let val = {
          num: item.num,
          id: item.id,
          productId: item.productId,
        };
        changeProducts.push(val);
      });
      if (total < 1) {
        this.$toast("产品总数量必须大于0");
        return;
      }
      settleOncreditOrder({
        orderId: this.searchKey,
        changeProducts,
      })
        .then((res) => {
          if (res) {
            this.$router.replace({
              path: "/record/ConfirmOrder",
              query: {
                type: "closeoutaccount",
                orderId: res.orderId,
                totalPrice: res.settlementPrice,
                settlementPrice: res.settlementPrice,
                refundPrice: res.refundPrice,
                showSearch: this.showSearch,
              },
            });
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
// 搜索框
.search-box {
  background: #fff;
  border-radius: 4px;
  height: 34px;
  .search-inp {
    border: none;
    height: 34px;
    width: 55vw;
  }
  .search-btn {
    color: #fff;
    border: none;
    width: 60px;
    height: 30px;
    border-radius: 6px;
    margin-right: 2px;
  }
}
.list {
  margin-bottom: 60px;
  .item {
    border-bottom: 1px solid #f1f1f1;
    background: #fff;
    color: #666;
    .num-box {
      background: #f3f3f3;
      border-radius: 6px;
      width: 50%;
      .num-inp {
        width: 50%;
        border: none;
        background: #f3f3f3;
      }
    }
  }
}

// 底部按钮
.foot {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: #fff;
  .foot-btn {
    color: #fff;
    border: none;
    width: 100%;
    border-radius: 20px;
  }
}
</style>
