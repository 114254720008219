<template>
  <div class="detail-view">
    <!-- 导航栏 -->
    <van-nav-bar
      fixed
      placeholder
      title="产品"
      left-arrow
      @click-left="$router.back()"
    />
    <!-- 加载更多 -->
    <van-list
      v-if="list.length !== 0"
      v-model="loading"
      @load="onLoad"
      :finished="noMore"
      :error.sync="error"
      error-text="请求失败，点击重新加载"
      :immediate-check="false"
    >
      <!-- 产品列表 -->
      <template #default>
        <div
          class="row-view"
          v-for="item in list"
          :key="item.id"
          @click="detailBtn(item.auxiliaryId)"
        >
          <div class="mr-2x">
            <div class="bold font-l">{{ item.productName }}</div>
            <div class="mt-1x gray">
              下单时间： {{ item.createTime | date("yyyy-mm-dd hh:MM:ss") }}
            </div>
          </div>
          <div class="align-end flex-shrink">
            <span class="light-blue mr-1x" :style="{ color: color }">详情</span>
            <van-icon name="arrow" size="14" :color="color" />
          </div>
        </div>
      </template>
    </van-list>
    <!-- 暂无数据 -->
    <van-empty
      v-if="list.length === 0"
      image="error"
      description="暂无数据"
    ></van-empty>
    <!-- 加载更多 -->
  </div>
</template>

<script>
import { orderMemberProduct } from "../../api/search.js";
export default {
  name: "MemberProduct",
  data() {
    return {
      list: [], // 产品列表
      page: 1,
      total: 0, // 列表总条数
      noMore: false, // 没有更多
      loading: false, // 加载分页数据
      error: false, // 请求错误
      mobile: "",
    };
  },
  computed: {
    // 主题配色变量
    color() {
      return this.$store.getters["common/color"].primary;
    },
  },
  // 进入页面触发
  created() {
    const { mobile } = this.$route.query;
    if (mobile) {
      this.mobile = mobile;
      this.loadData();
    }
  },
  methods: {
    // 加载更多
    onLoad() {
      if (this.list.length < this.total) {
        this.page++;
        this.loadData();
      }
    },
    // 加载数据
    loadData() {
      orderMemberProduct({
        keyword: this.mobile,
        page: this.page,
        size: 15,
      })
        .then(({ records, total }) => {
          if (this.page === 1) {
            this.list = records;
          } else {
            this.list = this.list.concat(records);
          }
          this.noMore =
            records.length === 0 || total < 10 || this.list.length === total;
          this.total = total;
          this.loading = false;
        })
        .catch(() => {
          this.error = true;
        });
    },
    detailBtn(id) {
      this.$router.push({
        path: "/record/MemberProductDetail",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>
<style scoped lang="less">
@import "./detail";
</style>
