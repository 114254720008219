<template>
  <div>
    <!-- 导航栏 -->
    <van-nav-bar
      fixed
      placeholder
      title="产品详情"
      left-arrow
      @click-left="$router.back()"
    />
    <!-- 产品详情 且只有一个票号时显示 -->
    <ticket-detail
      v-if="showDetail"
      :result-detail="resultDetail"
      @search-detail="searchDetail"
    ></ticket-detail>
    <!-- 暂无数据 -->
    <van-empty v-else image="error" description="暂无数据"></van-empty>
  </div>
</template>

<script>
import { orderMobilemanagerProductdetails } from "../../api/search";
import TicketDetail from "./TicketDetail.vue";
export default {
  name: "member-product-detail",
  components: {
    TicketDetail,
  },
  data() {
    return {
      keyword: "", // 票号
      resultDetail: {}, //产品详情数据
      showDetail: false, // 显示详情
    };
  },
  created() {
    const { id } = this.$route.query;
    this.keyword = id;
    this.loadData();
  },
  computed: {
    // 主题配色变量
    color() {
      return this.$store.getters["common/color"].primary;
    },
  },
  methods: {
    loadData() {
      orderMobilemanagerProductdetails({
        keyword: this.keyword,
      })
        .then((res) => {
          if (res.length === 1) {
            this.resultDetail = res[0];
            this.showDetail = true;
          }
        })
        .catch(() => {});
    },
    //订单详情- 根据票号查门票详情接口
    searchDetail(val) {
      this.keyword = val;
      this.loadData();
    },
  },
};
</script>

<style lang="scss" scoped></style>
