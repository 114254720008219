<template>
  <div>
    <!-- 导航栏 -->
    <van-nav-bar
      fixed
      placeholder
      :title="title"
      left-arrow
      @click-left="$router.back()"
    />
    <!-- 头部 -->
    <div class="infos" :style="{ background: color }">
      <div class="mb-2x">
        {{ type === "balance" ? "剩余余额" : "永久积分" }}
      </div>
      <div class="infos-price" v-if="type === 'balance'">
        {{ memberDetail.buyBalance | money() }}
      </div>
      <div class="infos-price" v-else>{{ memberDetail.usableIntegral }}</div>
      <div class="flex-end mt-2x" v-if="type === 'balance'">
        <button
          class="btn refund-btn mr-1x"
          hover-class="click"
          :style="{ background: color }"
          @click="refund"
        >
          退款
        </button>
        <button
          class="btn refund-btn mr-1x"
          hover-class="click"
          :style="{ background: color }"
          @click="balanceDetail"
        >
          明细
        </button>
        <button
          class="btn recharge-btn"
          :style="{ color: color }"
          hover-class="click"
          @click="recharge"
        >
          充值
        </button>
      </div>
    </div>
    <div class="balance-title">
      {{ type === "balance" ? "余额" : "积分" }}明细
    </div>
    <!-- 数据列表 -->
    <div class="detail-view">
      <van-list
        v-if="dataList.length !== 0"
        v-model="loading"
        :finished="noMore"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        :immediate-check="false"
        @load="loadMore"
      >
        <template #default>
          <div class="row-view" v-for="(item, idx) in dataList" :key="idx">
            <span class="disabled">{{ item.memberBusinessTypeText }}</span>
            <span
              >{{ item.tradingType === 0 ? "+" : "-"
              }}{{ item.changeNum }}</span
            >
          </div>
        </template>
      </van-list>
      <!-- 暂无数据 -->
      <van-empty
        v-if="dataList.length === 0"
        image="error"
        description="暂无数据"
      ></van-empty>
    </div>
  </div>
</template>

<script>
import {
  evidenceOneMemberInfo,
  balanceRecordInfo,
  integralRecordInfo,
} from "../../api/member.js"; // MS-会员接口
import { moneyFormat } from "../../utils/global";
export default {
  name: "MemberBalance",
  data() {
    return {
      type: "", // 类型
      mobile: "", // 会员手机号
      memberDetail: [], // 会员信息
      dataList: [], // 明细数据
      page: 1,
      total: 0, // 列表总条数
      noMore: false, // 没有更多
      title: "", //标题名称
      loading: false, // 加载分页数据
      error: false, // 请求错误
    };
  },
  created() {
    const { type, mobile } = this.$route.query;
    this.title = type === "balance" ? "余额" : "积分";
    this.type = type;
    this.mobile = mobile; // 会员手机号
    this.getUserInfo();
  },
  computed: {
    // 主题配色变量
    color() {
      return this.$store.getters["common/color"].primary;
    },
  },
  methods: {
    // 加载更多
    loadMore() {
      if (this.dataList.length < this.total) {
        this.page++;
        this.getDataList();
      }
    },
    // 获取数据
    getDataList() {
      let api = integralRecordInfo;
      if (this.type === "balance") {
        api = balanceRecordInfo;
      }
      api({
        id: this.memberDetail.id,
        page: this.page,
        size: 10,
      })
        .then(({ records, total }) => {
          records.forEach((item) => {
            if (this.type === "balance") {
              item.changeNum = moneyFormat(item.changeNum, "");
            }
          });
          if (this.page === 1) {
            this.dataList = records;
          } else {
            this.dataList = this.dataList.concat(records);
          }
          this.noMore =
            records.length === 0 ||
            total < 10 ||
            this.dataList.length === total;
          this.total = total;
          this.loading = false;
        })
        .catch(() => {
          this.error = true;
        });
    },
    // 获取会员信息
    getUserInfo() {
      evidenceOneMemberInfo({
        evidence: this.mobile,
      })
        .then((res) => {
          this.memberDetail = res;
          this.getDataList();
        })
        .catch(() => {});
    },
    // 充值
    recharge() {
      this.$router.push({
        path: "/record/RechargeCombo",
        query: {
          memberId: this.memberDetail.id,
          evidence: this.mobile,
        },
      });
    },
    // 明细
    balanceDetail() {
      this.$router.push({
        path: "/record/BalanceDetail",
        query: {
          memberId: this.memberDetail.id,
        },
      });
    },
    // 退款
    refund() {
      this.$router.push({
        path: "/record/MemberRefund",
        query: {
          memberId: this.memberDetail.id,
        },
      });
    },
  },
};
</script>
<style>
page {
  background: #fff;
}
</style>
<style lang="less" scoped>
@import "./detail";
.infos {
  padding: 20px;
  color: #ffffff;
  background: @primary;
  .infos-price {
    font-size: 32px;
    font-weight: 400;
  }
  .recharge-btn {
    width: 80px;
    background: #ffffff;
    color: #333;
    border: none;
  }
  .refund-btn {
    width: 62px;
    border: 1px solid #ffffff;
    color: #fff;
    background: @primary;
  }
  .btn {
    height: 30px;
    border-radius: 15px;
    font-size: 14px;
    line-height: 30px;
  }
  .btn::after {
    border: none;
  }
}
.balance-title {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  padding: 17px 0px;
  border-bottom: 1px solid #efefef;
  background: #ffffff;
}
</style>
