var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('van-nav-bar',{attrs:{"fixed":"","placeholder":"","title":"优惠券","left-arrow":""},on:{"click-left":function($event){return _vm.$router.back()}}}),_c('div',{staticClass:"flex-around coupon-type"},[_c('div',{staticClass:"coupon-type-item",class:{ selected: _vm.typeId === 1 },style:({
        color: _vm.typeId === 1 ? _vm.color : '',
        'border-color': _vm.typeId === 1 ? _vm.color : '',
      }),on:{"click":function($event){return _vm.changeTypeId(1)}}},[_vm._v(" 可用（"+_vm._s(_vm.couponsAvailableCount)+"） ")]),_c('div',{staticClass:"coupon-type-item",class:{ selected: _vm.typeId === 2 },style:({
        color: _vm.typeId === 2 ? _vm.color : '',
        'border-color': _vm.typeId === 2 ? _vm.color : '',
      }),on:{"click":function($event){return _vm.changeTypeId(2)}}},[_vm._v(" 过期（"+_vm._s(_vm.unavailableCount)+"） ")]),_c('div',{staticClass:"coupon-type-item",class:{ selected: _vm.typeId === 3 },style:({
        color: _vm.typeId === 3 ? _vm.color : '',
        'border-color': _vm.typeId === 3 ? _vm.color : '',
      }),on:{"click":function($event){return _vm.changeTypeId(3)}}},[_vm._v(" 已使用（"+_vm._s(_vm.expiredCount)+"） ")])]),_c('div',{staticClass:"p-2x"},[_vm._l((_vm.couponList),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"coupon-info flex-between"},[_c('div',{staticClass:"coupon-bg"},[_vm._v(" "+_vm._s(item.discountedPriceText)+" ")]),_c('div',{staticClass:"coupon-info-r"},[_c('div',{},[_vm._v(_vm._s(item.useConditionsText))]),_c('div',{staticClass:"validity mt-1x"},[_vm._v(" 有效期： "+_vm._s(_vm._f("date")(item.activityStartDate,"yyyy-mm-dd hh:MM:ss"))+" ~ "+_vm._s(_vm._f("date")(item.activityEndDate,"yyyy-mm-dd hh:MM:ss"))+" ")])])]),_c('div',{staticClass:"coupon-details"},[_vm._v(" 适用产品："),_c('span',{staticClass:"validity"},[_vm._v(" "+_vm._s(item.productText)+" ")])])])}),(_vm.couponList.length === 0)?_c('van-empty',{attrs:{"image":"error","description":"暂无优惠券"}}):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }