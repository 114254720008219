<template>
  <div>
    <!-- 导航栏 -->
    <van-nav-bar
      fixed
      placeholder
      title="余额明细"
      left-arrow
      @click-left="$router.back()"
    />
    <div class="p-2x">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="coupon-info flex-between">
          <div class="bold">
            {{ item.productName }}
          </div>
          <div class="coupon-info-r">
            <div class="text-r">{{ item.remainingSum | money() }}</div>
          </div>
        </div>
        <div class="valid-time font-s">
          有效期：<span class="validity">{{
            item.assetValidTime | date("yyyy-mm-dd hh:MM:ss")
          }}</span>
        </div>
        <div class="coupon-details font-s" v-if="item.applicationNameArr">
          <div class="flex-between">
            <span class="">可用产品：</span>
            <div
              class="flex-center align-end flex-shrink"
              @click="clickProduct(item, 1)"
            >
              <span class="light-blue mr-1x">查看更多</span>
              <span class="icon icon-Return- light-blue"></span>
            </div>
          </div>
          <div class="validity text-3">
            {{ item.applicationNameArr }}
          </div>
        </div>
      </div>
    </div>
    <!-- 暂无数据 -->
    <van-empty
      v-if="list.length === 0"
      image="error"
      description="暂无数据"
    ></van-empty>
    <!-- 可用产品详情 -->
    <van-dialog v-model="showModal" title="可用产品">
      <div class="slot-content" @click="confirm">
        {{ content }}
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { findMemberProperty } from "../../api/member";
export default {
  name: "BalanceDetail",
  data() {
    return {
      memberId: 0,
      list: [],
      showModal: false, // 显示弹窗
      content: "", // 弹窗内容
      title: "可用产品", // 弹窗标题
    };
  },
  created() {
    const { memberId } = this.$route.query;
    this.memberId = memberId;
    this.loadData();
  },
  methods: {
    // 显示弹窗
    clickProduct(item, type) {
      if (type === 0) {
        this.content = item.giftCoupons.join(";");
        this.title = "赠送优惠券";
      } else {
        this.title = "可用产品";
        this.content = item.applicationNameArr || "全部产品";
      }

      this.showModal = true;
    },
    // 关闭弹窗
    confirm() {
      this.showModal = false;
    },
    // 加载数据
    loadData() {
      findMemberProperty({
        memberId: this.memberId,
      })
        .then((res) => {
          res.forEach((item) => {
            if (item.applicableProducts) {
              let applicationNameArr = [];
              item.applicableProducts.forEach((temp) => {
                applicationNameArr.push(temp.productName);
              });
              item.applicationNameArr = applicationNameArr.join(";");
            }
          });
          console.log(res);
          this.list = res;
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.item {
  background: #fff;
  border-radius: 4px;
  margin-bottom: 10px;
  .coupon-info {
    padding: 11px 15px 13px;
    border-bottom: 1px solid #f1f1f1;
    .coupon-info-r {
      width: 20%;
    }
  }
  .validity {
    color: #8a8a8a;
  }
  .valid-time {
    padding: 11px 15px 11px;
  }
  .coupon-details {
    padding: 0 15px 13px;
  }
}
.slot-content {
  width: 320px;
  padding: 15px;
  max-height: 70vh;
}
.light-blue {
  color: #338ce5;
}
</style>
